@import "ui/src/styles/theme";

@mixin divider_line() {
  position: absolute;
  content: '';
  height: 1px;
  width: 46%;
  background-color: $primary-light;
  top: 50%;
}

.mobile {
  position: absolute;
  width: 100%;
  background: white;
  height: 100vh;
}

.cta {
  padding: 12px;
  width: 100%;
  transition: all ease-out 0.2s;
  &:hover {
    cursor: pointer;
    background-color: transparent;
    color: $primary;
  }
}

.autosuggestInput {
  border: none;
  background-color: $primary-light;
  border-radius: $border-radius;
  &::placeholder {
    color: $primary;
  }
  &::-ms-input-placeholder {
    color: $primary;
  }
  &:-ms-input-placeholder {
    color: $primary;
  }
}

.divider {
  position: relative;
  margin: spacing(6, 0);
  text-align: center;
  text-transform: uppercase;
  color: $primary;

  &:before, &:after {
    @include divider_line();
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}

@include media-breakpoint-up(md) {
  .divider:before, .divider:after {
    width: 48%;
  }
}
